import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchRecipe, fetchRecipes } from '../recipes/utils'
import Loading from '../recipes/components/Loading'
import Header from '../recipes/components/Header'
import { AiFillPushpin } from "react-icons/ai"
import { BsPatchCheck } from "react-icons/bs"
import RecipeCard from '../recipes/components/RecipeCard'


const RecipeDetail = () => {
  const [recipe, setRecipe] = useState(null)
  const [recipes, setRecipes] = useState([])
  const [loading, setLoading] = useState(false)

  const { id } = useParams()

  const getRecipe = async (id) => {
    try {
      setLoading(true)

      const data = await fetchRecipe(id)

      setRecipe(data)

      const recommend = await fetchRecipes({ query: recipe?.label, limit: 3 })

      setRecipes(recommend)

      setLoading(false)


    } catch (error) {
      console.log(error)

      setLoading(false)
    }
  }

  useEffect(() => {
    getRecipe(id)
  }, [id])


  if (loading) {
    return (
      <div className='w-full h-[100vh] flex items-center justify-center'>
        <Loading />
      </div>
    );
  }
  return (
    <div className='flex justify-center items-start'>
    <div className='w-full flex justify-center flex-col'>
      
      <div className='flex justify-center flex-col  px-4  pt-5 bg-slate-100'>
      {/* <div className='flex justify-center flex-col w-4/5 px-4 lg:px-20 pt-5 bg-slate-100'> */}

        <div className='flex gap-10 items-center  py-4 bg-white px-10 max-sm:flex-col ' >
          <div className=' ' id="foodImg">
            <img className="rounded-lg shadow-md"  src={recipe?.image} />
          </div>
          <div>
              <div>
                <div className='text-5xl font-bold py-10 max-sm:text-2xl, py-5'>{recipe?.label}</div>
              </div>
              <div>
                <div className='flex bg-[#3c91e6] w-fit py-1 px-3 rounded-lg'>{recipe?.cuisineType}</div>
              </div>
              <div className='flex gap-5 py-10'>
                    <div className='flex flex-col justify-between'>
                      <span className='text-black text-center border border-gray-500 py-1.5 px-2 rounded-full mb-2'>{recipe?.calories.toFixed(2)} </span>

                      <p className='text-black text-[12px] md:text-md'>&nbsp;&nbsp;CALORIES</p>
                    </div>

                    <div className='flex flex-col justify-center'>
                      <span className='text-black text-center border border-gray-500 py-1.5 rounded-full mb-2'>
                        {recipe?.totalTime}
                      </span>
                      <p className='text-black text-[12px] md:text-md'>
                        TOTAL TIME
                      </p>
                    </div>

                    <div className='flex flex-col justify-center'>
                      <span className='text-black text-center border border-gray-500 py-1.5 rounded-full mb-2'>
                        {recipe?.yield}
                      </span>
                      <p className='text-black text-[12px] md:text-md'>SERVINGS</p>
                    </div>
              </div>
          </div>


        </div>
        <div className='h-5 bg-slate-100'></div>
        <div className='w-full flex flex-col md:flex-row gap-8 py-20 px-4 md:px-10 bg-white'>
          {/* LEFT SIDE */}
          <div className='w-full md:w-2/4 md:border-r border-slate-800 pr-1'>
            <div className='flex flex-col gap-5'>
              <p className='text-black text-2xl font-bold'>Ingredients</p>

              {
                recipe?.ingredientLines?.map((ingredient, index) => {
                  return (
                    <p key={index} className='text-black flex gap-2'>
                      <AiFillPushpin className='text-black text-xl' /> {ingredient}
                    </p>
                  )
                })
              }
            </div>

            <div className='flex flex-col gap-3 mt-20'>
              <p className='text-black text-2xl font-bold'>Health Labels</p>

              <div className='flex flex-wrap gap-4'>
                {
                  recipe?.healthLabels.map((item, index) => (
                    <p className='text-black flex gap-2 bg-[#fff5f518] px-4 py-1 rounded-full ' key={index}>
                      <BsPatchCheck color='green' /> {item}
                    </p>
                  ))
                }

              </div>
            </div>
          </div>


          {/* RIGHT SIDE */}
          <div className='w-full md:w-2/4 2xl:pl-10 mt-20 md:mt-0'>
            {
              recipes?.length > 0 && (
                <>
                  <p className='text-black text-2xl font-bold'>Also Try This</p>

                  <div className='grid grid-rows-2 flex-wrap gap-9 px-1 pt-3'>
                    {
                      recipes?.map((item, index) => (
                        <RecipeCard recipe={item} index={index} />
                      ))
                    }
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default RecipeDetail