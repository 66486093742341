// export  function getRandomMenus(array: Array<string>, numElements: number) {
//             const randomElements = [];

//     // Ensure numElements is not greater than the array length
//             numElements = Math.min(numElements, array.length);

//             while (randomElements.length < numElements) {
//         // Generate a random index within the array's length
//                 const randomIndex = Math.floor(Math.random() * array.length);
        
//         // Check if the element is already in the randomElements array to avoid duplicates
//                     if (!randomElements.includes(array[randomIndex])) {
//                         randomElements.push(array[randomIndex]);
//                     }
//             }

//              return randomElements;
// }

export function getRandomMenus(array: Array<{ option: string }>, numElements: number) {
        const randomElements = [];
        // Ensure numElements is not greater than the array length
        numElements = Math.min(numElements, array.length);
    
        while (randomElements.length < numElements) {
            // Generate a random index within the array's length
            const randomIndex = Math.floor(Math.random() * array.length);
    
            // Check if the element is already in the randomElements array to avoid duplicates
            if (!randomElements.some(item => item.option === array[randomIndex].option)) {
                randomElements.push({ option: array[randomIndex].option });
            }
        }
    
        return randomElements;
    }
    