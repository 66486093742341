import React from 'react';

const GoogleMap = (prop) => {
  // Specify the keyword or location you want to search for
  const keyword = prop.result+" restaurant";

  // Create the Google Maps URL with the search query parameter
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(keyword)}`;

  // Handle the button click to open Google Maps
  const handleButtonClick = () => {
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <div
      className="justify-center bg-sky-500 text-black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 max-sm:px-2"
    >
      {/* <p>Click the button to open Google Maps with the specified keyword:</p> */}
      <button 
        onClick={handleButtonClick}>FIND PLACE IN GOOGLE MAPS</button>
    </div>
  );
};

export default GoogleMap;
