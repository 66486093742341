
export async function fetchRecipes (filter){
    const {query, limit} = filter;

    const url = `https://api.edamam.com/search?q=${query}&app_id=${process.env.REACT_APP_EDAMAM_APP_ID}&app_key=${process.env.REACT_APP_EDAMAM_API_KEY}&from=0&to=${limit}&`;


    const response = await fetch(url)

    const data = await response.json();

    console.log("",data)

    return data?.hits;
}

export async function fetchRecipe(id){
    const url = `https://api.edamam.com/search?r=http://www.edamam.com/ontologies/edamam.owl%23${id}&app_id=${process.env.REACT_APP_EDAMAM_APP_ID}&app_key=${process.env.REACT_APP_EDAMAM_API_KEY}`
    
    const response = await fetch(url)
    
    const data = await response.json();
    
    return data[0];
    }

export async function fetchFood(id){
    const url = `https://www.themealdb.com/api/json/v1/1/lookup.php?i=${id}`

    const response = await fetch(url)

    const data = await response.json();

return data;
}

export async function fetchFoods(keyword){
    const url = `https://www.themealdb.com/api/json/v1/1/search.php?s=${keyword}`

    const response = await fetch(url)

    const data = await response.json();
    
    return data;
}