import Button from '@mui/material/Button';
import React, { useEffect,useState, useRef } from "react";

import {data} from "../data"
import { getRandomMenus } from "../components/foods";
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable'

import { menuOptions } from '../data';
import Fireworks from '../components/Fireworks'
// import WheelComponent from './WheelFunction'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import Result from '../components/Result'
import axios from 'axios';
import { Wheel } from '../core/components/Wheel'
import { TypeAnimation } from 'react-type-animation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Home.css'
const animatedComponents = makeAnimated();
var randomColor = require('randomcolor');


export default function WheelMain ()  {

  const initData = [
    "Pizza", "Hambuger", "Sushi", "Taco", "Hot Dog",
    "Chicken Wings", "Pho", "Curry", "BBQ", "Spaghetti",
    "Sandwitch", "Steak", "Salmon", "Fried rice", "Bibibop", "Chilli",
    // "Gyros", "Fried Shrimp", "Lasagna", "Brito", "Chili"
  ];

  const defaultColor = [
    '#EE4040',
    '#F0CF50',
    '#815CD1',
    '#3DA5E0',
    '#34A24F',
    '#F9AA1F',
    '#EC3F3F',
    '#FF9000'
  ]

  // initData=getRandomMenus(data,16)
  const [segColors, setSegColors] = useState(defaultColor)
  const [foods, setFoods] = useState(getRandomMenus(data,16))
  const [myMenus, setMymenus] = useState([])
  const [menuAdd, setMenuAdd] = useState(true)
  const [menualClick, setMenualClick] = useState(false)
  const [randomClick, setRandomClick] = useState(true)
  const [selected, setSelected] = useState(null);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [finished, setFinished] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [radiusColor, setRadiusColor]=useState("black")
  const selectItemRef = useRef(null);
  let menualMenus = []
  let colors=[]
  
  const [result, setResult] = useState("")

  const finalMenus = (winner) => {
    console.log("final menus",winner);
  };

  const changingMenu = (selectedMenus)=>{
        setResult("");
        setSelected(selectedMenus);
        
        const convertData = selectedMenus.map(item => ({ option: item.value }));
        convertData.map((food)=>menualMenus.push(food.value))
        // foodUpdate(menualMenus)
        foodUpdate(convertData)
        colors = randomColor({
          count:convertData.length
       })
      // setSegColors(colors)
        colorUpdate(colors)
        // onblur={()={}}
    }
    
  const foodUpdate = (selectedMenus)=>{
    setFoods(selectedMenus)
    return foods
  }

  const colorUpdate=(selectedColor)=>{
    setSegColors(selectedColor) 
     return segColors
  }

  const randomMenu = ()=> {
    setResult("");
    if(menualClick === true){
      setMenualClick(false) 
    }
    foodUpdate(getRandomMenus(data, 16))
    colors = randomColor({
      count:16
   })
    colorUpdate(colors)
  }

  const manualMenu=()=>{
    setResult("");
    menualClick === false ? setMenualClick(true) : setMenualClick(false)
    if (randomClick === true){
      setRandomClick(false)
    }
    setMymenus(initData)
    setMenuAdd(true)
  }

  const handleSpinClick = () => {
    console.log(foods.length)
    if (!foods.length){
      toast.error("Please add foods")
      // alert("nope")
    }

    if (!mustSpin && foods.length) {
      const newPrizeNumber = Math.floor(Math.random() * foods.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
      setFinished(false);
      setResult(foods[newPrizeNumber]?.option)
    }
  }
  
 useEffect(()=>{
  // eslint-disable-next-line react-hooks/exhaustive-deps
     colors = randomColor({
        count:foods.length
    })

    if (!foods.length) setRadiusColor("transparent")

    colorUpdate(colors)
    // setSegColors((state)=>{
    //   setSegColors(state)
    //   console.log(state)
    //   return segColors
    // })
  },[foods])

  // ==================
  const [videos, setVideos] = useState([]);

  const searchVideos = async (term) => {
    const API_KEY = 'api key add';
    const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
      params: {
        q: term,
        part: 'snippet',
        type: 'video',
        key: API_KEY,
      },
    });
    setVideos(response.data.items);
  };

  const { width, height } = useWindowSize()

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#212529" : "#fff",
      backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#212529",
      padding: "10px",
      border: "none",
      boxShadow: "none",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };

  
  return (
    <>
        {/* <ul className='flex w-full py-2 md:py-3 px-4 md:px-20 items-center justify-start'>
                        <li>
                            <a href="/" className='flex items-center justify-center text-white text-lg cursor-pointer'>Home</a>
                        </li>
        </ul> */}
    <div className='flex justify-center h-full bg-black opacity-90 text-4xl max-sm:text-xl' id="topTitle">
        <TypeAnimation
          className="max-sm:text-3xl max-sm:tracking-tight max-sm:pb-3 pb-5 bg-gradient-to-r from-orange-400 via-red-500 to-purple-600 bg-clip-text text-transparent text-6xl drop-shadow font-extrabold flex justify-center"
          sequence={[
            'What Should U Eat?',
            1500, // wait 1s before replacing "Mice" with "Hamsters"
            'What Should I Eat?',
            1500,
            'What Should We Eat?',
            1500,
          ]}
          wrapper="span"
          speed={50}
          style={{ fontSize: '2em', display: 'inline-block' }}
          repeat={Infinity}
        />

      </div>
    <div className="flex flex-col bg-gradient-to-r from-cyan-500 to-blue-800 ...  max-sm:h-screen1">
    {/* <div className="flex flex-col "> */}
      <div className="flex flex-col  items-center rounded-lg pt-10">
        {/* <ul className='flex flex-col items-start w-260 bg-blue-100 p-3 ml-0 rounded-md shadow-md'> */}
        <ul className=' flex flex-col items-start w-260  p-3 ml-0 bg-sky-500 rounded-lg p-10'>
         <p className='flex text-2xl max-sm:text-xl font-bold content-center'>Please select your preferred mode:</p> 
          <li className='w-160 tracking-tight' id="randomP" >
           1 . <b>Random</b>: Menus are chosen randomly.
          </li>
          <li className='flex flex-col'>
              <p>2. <b>Manual:</b></p>
              <p className='pl-3.5 leading-3' id="lineSize">&#x2022; Use the select list to add menus.</p>
              <p className='pl-3.5 leading-5'>&#x2022; Alternatively, type in your desired menus to add them to the wheel.</p>
          </li>
        </ul>
        {/* <ul>
          <li>2. Click <text className='font-bold'>"Click here"</text> at the center of the wheel</li>
        </ul> */}
        <span className="font-sans text-4xl font-bold max-sm:text-2xl p-3 ">Choose your menu</span>
           <div className="flex py-3">
              <div className="pr-5">
                  <Button sx={{color: "black"}} variant="contained" onClick={randomMenu}> Random </Button>
              </div>
              <div>
                  <Button sx={{color: "black"}} variant="contained" onClick={manualMenu}> Manual </Button>
              </div>
          </div>
      </div>    

      <div className="menuSelect">

      {menualClick && 
                  <CreatableSelect
                      styles={customStyles}
                      closeMenuOnSelect={false}
                      isMulti
                      components={animatedComponents}
                      options={menuOptions}
                      onChange={changingMenu}
                      className="menuSelection"
                      menuPlacement="top"
                      openMenuOnClick={true}
                      ref={selectItemRef}
                      blurInputOnSelect={false}
                  />}
      </div>

        {/* <div id="canvas" style={{ position: 'relative' }}> */}
        <div id="canvas" className='pt-5' >
          <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={foods}
              backgroundColors={segColors}
              // backgroundColors={defaultColor}
              textColors={['#ffffff']}
              spinDuration={0.2}
              onStopSpinning={() => {
                setMustSpin(false);
                setFinished(true)
              }}
              fontSize={15}
              radiusLineColor={radiusColor}
          />
            <button className="centeredButton h-24 w-24 max-sm:h-16 max-sm:w-16" onClick={handleSpinClick}>
            <p id="clickBt" className='text-2xl text-left max-sm:text-xs'>Click</p>
            <p id="clickBt" className='text-2xl text-left max-sm:text-xs'> Here</p>
            </button> 
            
            <ToastContainer
              position="top-center"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
              className="text-xl"
            />
      </div>
      <div className='h-20'></div>
    </div>

      <div>
          {finished && <Fireworks result={result} />}
      </div>

      {finished && <Confetti
          width={width}
          height={height}
          recycle={false}
      />}  
  
      {finished && <Result result={result} />}
      {/* <VideoList videos={video1s} /> */}
      {/* <SoloWheel foods={foods} colors={segColors} result={result} /> */}
    
    
    </>
  );
};

