import React, {useEffect} from "react";
import { useNavigate, Link } from 'react-router-dom';
import GoogleMap from './GoogleMap'
import Youtube from './youtube'
// import VideoSearch from './VideoSearch'

export default function Result(prop) {
    // const navigate = useNavigate();
    
    const [showModal, setShowModal] = React.useState(false);
    let result = prop.result
       
    const closeRefresh=()=>{
        setShowModal(false)
    }
    const playAgain = () => {
        // navigate('/'); // Navigate to the same route
         window.location.reload(); // Force refresh the page
    };

    useEffect(()=>{
        // result.length !==0 ? setShowModal(true) : ""
        if (result.length !==0) {
            setShowModal(true);
        }
    },[prop.result])

    
    return (
        <>
        {showModal ? (
            <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl max-sm:max-w-sm">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" id="modalContainer">
                    {/*header*/}
                    <div className="flex items-start justify-between p-1 rounded-t">
                    {/* <h3 className="text-3xl font-semibold">
                        You should eat {result}
                    </h3> */}
                    <button
                        className="p-1 ml-auto  border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={closeRefresh}
                    >
                        <span className="text-black w-6 text-2xl block outline-none focus:outline-none ">
                        ×
                        </span>
                    </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-1  px-6 flex-auto" id="result">
                    <p className="my-4  text-3xl leading-relaxed max-sm:text-2xl" id="resultext">
                    You should eat  <span className="bg-gradient-to-r from-orange-400 via-red-500 to-purple-600 bg-clip-text text-transparent text-6xl drop-shadow font-extrabold flex justify-center max-sm:text-4xl, p-2">{result}</span>
                    </p>
                    </div>
                    
                    {/*footer*/}

                    <div className="flex-col flex p-2  rounded-b">
                        <div  
                        
                                className="flex bg-sky-500 text-black active:bg-sky-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 justify-center max-sm:px-2">
                             {/* onClick={handleRefresh}  */}
                            <button
                                type="button"
                                // onClick={() => setShowModal(false)}
                                onClick={playAgain}
                            >
                                PLAY AGAIN
                            </button>
                        </div>
                        <button>
                        <GoogleMap result={result}/>
                        </button>

                        <button>
                            <Youtube result={result}/>
                        </button>    
                        <button
                            className="bg-sky-500 text-black active:bg-sky-500 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 justify-center max-sm:px-2"
                        >
                            <Link 
                                to="/recipes"
                                state={{result: result}}
                            >Recipes</Link>
                            {/* <Link 
                                to="/foods"
                                state={{result: result}}
                            >Recipes</Link> */}
                        </button>
                    </div>
                </div>
                </div>
            </div>
            {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
            </>
        ) : null}
        </>
    );
}