import React, { createRef, RefObject, useEffect } from 'react';

import { WheelCanvasStyle } from './styles';
import { WheelData } from '../Wheel/types';
import { clamp, getQuantity } from '../../utils';

interface WheelCanvasProps extends DrawWheelProps {
  width: string;
  height: string;
  data: WheelData[];
}

interface DrawWheelProps {
  outerBorderColor: string;
  outerBorderWidth: number;
  innerRadius: number;
  innerBorderColor: string;
  innerBorderWidth: number;
  radiusLineColor: string;
  radiusLineWidth: number;
  fontFamily: string;
  fontWeight: number | string;
  fontSize: number;
  fontStyle: string;
  perpendicularText: boolean;
  prizeMap: number[][];
  rouletteUpdater: boolean;
  textDistance: number;
}

const drawRadialBorder = (
  ctx: CanvasRenderingContext2D,
  centerX: number,
  centerY: number,
  insideRadius: number,
  outsideRadius: number,
  angle: number,
) => {
  ctx.beginPath();
  ctx.moveTo(
    centerX + (insideRadius + 1) * Math.cos(angle),
    centerY + (insideRadius + 1) * Math.sin(angle)
  );
  ctx.lineTo(
    centerX + (outsideRadius - 1) * Math.cos(angle),
    centerY + (outsideRadius - 1) * Math.sin(angle)
  );
  ctx.closePath();
  ctx.stroke();
};

const drawBulbs = (ctx, centerX, centerY, radius, numBulbs) => {
  for (let i = 0; i < numBulbs; i++) {
    const angle = (Math.PI * 2 * i) / numBulbs;
    const bulbX = centerX + Math.cos(angle) * radius;
    const bulbY = centerY + Math.sin(angle) * radius;

    let gradientRadius = 18;
    let gradientCenterRadius = 18; // Adjust the size of the central bulb
    
    // Create a smaller white bulb at the center
    if (i === 0) {
      gradientRadius = gradientCenterRadius;
    }

    const bulbGradient = ctx.createRadialGradient(
      bulbX, bulbY, 0,
      bulbX, bulbY, gradientRadius
    );
    bulbGradient.addColorStop(0, 'white');
    bulbGradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.8)');
    bulbGradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

    ctx.fillStyle = bulbGradient;
    ctx.beginPath();
    ctx.arc(bulbX, bulbY, gradientRadius, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fill();
  }
};

const drawWheel = (
  canvasRef: RefObject<HTMLCanvasElement>,
  data: WheelData[],
  drawWheelProps: DrawWheelProps
) => {
  /* eslint-disable prefer-const */
  let {
    outerBorderColor,
    outerBorderWidth,
    innerRadius,
    innerBorderColor,
    innerBorderWidth,
    radiusLineColor,
    radiusLineWidth,
    fontFamily,
    fontWeight,
    fontSize,
    fontStyle,
    perpendicularText,
    prizeMap,
    textDistance,
  } = drawWheelProps;

  const QUANTITY = getQuantity(prizeMap);

  outerBorderWidth *= 2;
  innerBorderWidth *= 2;
  radiusLineWidth *= 0.7;

  const insideLine =() => {
    // const innerCircleRadius = insideRadius -5; // Adjust the radius as needed
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    
    ctx.strokeStyle = 'black'; // Set the border color to black
    ctx.lineWidth = 3; // Set the border line width for the inner circle
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    ctx.beginPath();
    ctx.arc(centerX, centerY, 403, 0, Math.PI * 2);
    ctx.closePath();
    ctx.stroke();
  }

  const drawStereoscopicShade = (ctx: CanvasRenderingContext2D, centerX: number, centerY: number, insideRadius: number, outsideRadius: number) => {
    const shadowGradient = ctx.createRadialGradient(centerX, centerY, insideRadius * 0.9, centerX, centerY, outsideRadius * 1.1);
    shadowGradient.addColorStop(0, 'rgba(0, 0, 0, 0.2)');
    shadowGradient.addColorStop(1, 'rgba(0, 0, 0, 0)');
    
    ctx.fillStyle = shadowGradient;
    ctx.beginPath();
    ctx.arc(centerX, centerY, outsideRadius, 0, Math.PI * 2);
    ctx.arc(centerX, centerY, insideRadius, 0, Math.PI * 2, true);
    ctx.fill();
  };
  
  const canvas = canvasRef.current;

  if (canvas?.getContext('2d')) {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.clearRect(0, 0, 850, 850);
    ctx.strokeStyle = 'transparent';
    ctx.lineWidth = 0;

    let startAngle = 0;
    const outsideRadius = canvas.width / 2 - 10;

    const clampedContentDistance = clamp(0, 100, textDistance);
    const contentRadius = (outsideRadius * clampedContentDistance) / 100;

    const clampedInsideRadius = clamp(0, 100, innerRadius);
    const insideRadius = (outsideRadius * clampedInsideRadius) / 100;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    
    // Draw the main wheel border
    ctx.strokeStyle = 'black'; // Set the border color to black
    ctx.lineWidth = 5; // Set the border line width

    ctx.beginPath();
    ctx.arc(centerX, centerY, outsideRadius+4, 0, Math.PI * 2);
    ctx.closePath();
    ctx.stroke();

//     ctx.beginPath();
// ctx.arc(centerX, centerY, outsideRadius+1, 0, 2 * Math.PI);
// ctx.closePath();
// ctx.stroke();

    
    for (let i = 0; i < data.length; i++) {
      const { optionSize, style } = data[i];

      const arc =
        (optionSize && (optionSize * (2 * Math.PI)) / QUANTITY) ||
        (2 * Math.PI) / QUANTITY;
      const endAngle = startAngle + arc;

      ctx.fillStyle = (style && style.backgroundColor) as string;

      ctx.beginPath();
      ctx.arc(centerX, centerY, outsideRadius, startAngle, endAngle, false);
      ctx.arc(centerX, centerY, insideRadius, endAngle, startAngle, true);
      ctx.stroke();
      ctx.fill();
      ctx.save();

      // WHEEL RADIUS LINES
      ctx.strokeStyle = radiusLineWidth <= 0 ? 'transparent' : radiusLineColor;
      ctx.lineWidth = radiusLineWidth;
      
      drawRadialBorder(
        ctx,
        centerX,
        centerY,
        insideRadius,
        outsideRadius,
        startAngle
      );
      if (i === data.length - 1) {
        drawRadialBorder(
          ctx,
          centerX,
          centerY,
          insideRadius,
          outsideRadius,
          endAngle
        );
      }
      
       //inside shade
      ctx.strokeStyle =
        outerBorderWidth <= 0 ? 'transparent' : "white";
      ctx.lineWidth = 37;
      ctx.beginPath();
      ctx.arc(
        centerX,
        centerY,
        405,
        0,
        2 * Math.PI
      );


      // WHEEL OUTER BORDER
      ctx.strokeStyle =
        outerBorderWidth <= 0 ? 'transparent' : "#ca4503";
        // outerBorderWidth <= 0 ? 'transparent' : "#1569C7";
      ctx.lineWidth = 35;
      // ctx.lineWidth = outerBorderWidth;
      ctx.beginPath();
      ctx.arc(
        centerX,
        centerY,
        (outsideRadius - ctx.lineWidth / 2),
        0,
        2 * Math.PI
      );
      ctx.closePath();
      ctx.stroke();
      
      // WHEEL INNER BORDER
      ctx.strokeStyle =
        innerBorderWidth <= 0 ? 'transparent' : innerBorderColor;
      ctx.lineWidth = innerBorderWidth;
      ctx.beginPath();
      ctx.arc(
        centerX,
        centerY,
        insideRadius + ctx.lineWidth / 2 - 1,
        0,
        2 * Math.PI
      );
      ctx.closePath();
      ctx.stroke();

      // CONTENT FILL
      ctx.translate(
        centerX + Math.cos(startAngle + arc / 2) * contentRadius,
        centerY + Math.sin(startAngle + arc / 2) * contentRadius
      );
      let contentRotationAngle = startAngle + arc / 2;

      if (data[i].image) {
        // CASE IMAGE
        contentRotationAngle +=
          data[i].image && !data[i].image?.landscape ? Math.PI / 2 : 0;
        ctx.rotate(contentRotationAngle);

        const img = data[i].image?._imageHTML || new Image();
        ctx.drawImage(
          img,
          (img.width + (data[i].image?.offsetX || 0)) / -2,
          -(
            img.height -
            (data[i].image?.landscape ? 0 : 90) + // offsetY correction for non landscape images
            (data[i].image?.offsetY || 0)
          ) / 2,
          img.width,
          img.height
        );
      } else {
        // CASE TEXT
        contentRotationAngle += perpendicularText ? Math.PI / 2 : 0;
        ctx.rotate(contentRotationAngle);

        const text = data[i].option;
        ctx.font = `${style?.fontStyle || fontStyle} ${
          style?.fontWeight || fontWeight
        } ${(style?.fontSize || fontSize) * 2}px ${
          style?.fontFamily || fontFamily
        }, Helvetica, Arial`;
        ctx.fillStyle = (style && style.textColor) as string;
        ctx.fillText(
          text || '',
          // -ctx.measureText(text || '').width / 2,
          (-ctx.measureText(text || '').width / 2)-25,
          fontSize / 2.7,
        );
      }
      ctx.restore();
      
      startAngle = endAngle;

    }
    // drawBulb()
    
    drawBulbs(ctx, centerX, centerY, outsideRadius-15, 12); // Adjust parameters as needed
    insideLine()
    drawStereoscopicShade(ctx, centerX, centerY, 380, 400);
  };


}

const WheelCanvas = ({
  width,
  height,
  data,
  outerBorderColor,
  outerBorderWidth,
  innerRadius,
  innerBorderColor,
  innerBorderWidth,
  radiusLineColor,
  radiusLineWidth,
  fontFamily,
  fontWeight,
  fontSize,
  fontStyle,
  perpendicularText,
  prizeMap,
  rouletteUpdater,
  textDistance,
}: WheelCanvasProps): JSX.Element => {
  const canvasRef = createRef<HTMLCanvasElement>();
  const drawWheelProps = {
    outerBorderColor,
    outerBorderWidth,
    innerRadius,
    innerBorderColor,
    innerBorderWidth,
    radiusLineColor,
    radiusLineWidth,
    fontFamily,
    fontWeight,
    fontSize,
    fontStyle,
    perpendicularText,
    prizeMap,
    rouletteUpdater,
    textDistance,
  };

  useEffect(() => {
    drawWheel(canvasRef, data, drawWheelProps);
  }, [canvasRef, data, drawWheelProps, rouletteUpdater]);

  return <WheelCanvasStyle ref={canvasRef} width={width} height={height} />;
};

export default WheelCanvas;
