'use client'

import confetti from 'canvas-confetti';
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";



function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0
};

function getAnimationSettings(originXA, originXB) {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
    particleCount: 550,
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2
    }
  };
}

export default function Fireworks({result}) {
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState<any>(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);
  
  // =====================First Action==================================
  useEffect(()=>{
    realisticLook();
  },[])

  const realisticLook =()=>{
    var count = 200;
    var defaults = {
      origin: { y: 0.7 }
    };
    
    function fire(particleRatio, opts) {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio)
      });
    }
    
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

// ===================FIREWORKS=============================
  if(result){
    var duration = 5 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
    
    var trigger = setInterval(function() {
      var timeLeft = animationEnd - Date.now();
    
      if (timeLeft <= 0) {
        return clearInterval(trigger);
      }
    
      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
      confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
    }, 250);
  }
  // ===================FIREWORKS=============================

// =====================School Pride========================
// if(result) {

//   var duration = 7 * 1000;
//   var end = Date.now() + duration;
//   var animationEnd = Date.now() + duration;
//   var colors = ['#bb0000', '#ffffff'];
  
//   // var trigger =()=>{
//     // var frame=()=> {
//       // launch a few confetti from the left edge
      
    
//       // keep going until we are out of time
//       // if (Date.now() < end) {
//       //   requestAnimationFrame(frame);
//       // }
//       var trigger = setInterval(function() {
//             var timeLeft = animationEnd - Date.now();
          
//             if (timeLeft <= 0) {
//               return clearInterval(trigger);
//             }
//             confetti({
//               particleCount: 7,
//               angle: 60,
//               spread: 55,
//               origin: { x: 0 },
//               colors
//             });
//             // and launch a few from the right edge
//             confetti({
//               particleCount: 7,
//               angle: 120,
//               spread: 55,
//               origin: { x: 1 },
//               colors
//             });
//       },250);
//     }
// }
// ========================School Pride=========================================
interface trigger {
  prevState: null
}
const startAnimation = useCallback(() =>{
    if (!intervalId) {
      // setIntervalId(setInterval(nextTickAnimation, 400));
      // setIntervalId(setInterval(frame(), 400));
      
      setIntervalId(setInterval(trigger, 400));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [intervalId, trigger]);
  
  if (result?.length !== 0) {
    startAnimation();
  }

  const pauseAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
  }, [intervalId]);

  const stopAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
    refAnimationInstance.current && refAnimationInstance.current.reset();
  }, [intervalId]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);
  
  

  return (
    <>
      <div>
        {/* <button onClick={startAnimation}>Start</button>
        <button onClick={pauseAnimation}>Pause</button>
        <button onClick={stopAnimation}>Stop</button> */}
      </div>
      <ReactCanvasConfetti refConfetti={getInstance}  />
    </>
  );
}
