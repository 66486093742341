import React, { useEffect } from 'react';
// import { useRoutes } from "react-router-dom";

export default function Youtube(result) {
        const keyword = result.result
        const openYouTubeSearch = () => {
          const searchQuery = encodeURIComponent(keyword);
          const youtubeUrl = `https://www.youtube.com/results?search_query=${searchQuery}`;
        
          const openInNewTab = (url) => {
                const newWindow = window.open(url, '_blank');
                if (newWindow) {
                newWindow.opener = null;
                }
          };
          openInNewTab(youtubeUrl);
          
        };

        return (
          <div className="justify-center bg-sky-500 text-black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 max-sm:px-2">
            <button  onClick={openYouTubeSearch}>FIND RECIPES IN YOUTUBE</button>
          </div>
        );
      }