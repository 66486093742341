import React, { useState } from 'react'
import Logo from './logo.png'
import { HiMenuAlt3 } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import Button from '../recipes/components/Button'

const Navbar = () => {
    const [open, setOpen] = useState(false)

    return (
        <header className=' w-full bg-black opacity-90'>
            <nav className='flex w-full  md:py-4 px-2 md:px-20 items-center justify-start max-sm:py-3'>
                <div>
                    <Link to={"/"} className='flex items-center justify-center text-white text-lg cursor-pointer '>
                    {/* <Link href="/" className='flex items-center justify-center text-white text-lg cursor-pointer'> */}
                        <img src={Logo} alt="Logo" className=' md:block w-8 h-8 lg:w-14 lg:h-14  rounded-md' />
                        {/* &nbsp;&nbsp;What&nbsp;<span>Should&nbsp;U&nbsp;Eat?</span> */}
                        <p className='px-5'>Home</p>
                    </Link>
                </div>
                <div>
                    <Link to={"about"} className='flex items-center justify-center text-white text-lg cursor-pointer '>
                        <p className='text-white'>About</p>
                    </Link>
                </div>

                {/* <ul className='hidden md:flex text-white gap-6'>
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        <a href="/#recipes">Explore</a>
                    </li>
                    <li>
                        <a href="/favorites">Favorites</a>
                    </li>
                </ul> */}

                {/* <Button
                    title='Sign in'
                    conteinerStyle='hidden md:block bg-transparent border border-white text-white hover:bg-white hover:text-slate-700 rounded-full min-w-[130px]'
                /> */}

                {/* <button className='block md:hidden text-white text-xl'
                    onClick={() => setOpen(prev => !prev)}>
                    {open ? <AiOutlineClose /> : <HiMenuAlt3 />}
                </button> */}
            </nav>
            <div className={`${open ? "flex" : "hidden"} bg-black flex-col w-full px-4 pt-16 pb-10 text-white gap-6 text-[14px]`}>
                <a href="/">Home</a>
                <a href="/#recipes">Recipes</a>
                <a href="/">Favorites</a>
            </div>
        </header>
    )
}

export default Navbar