export default function About () {

    return (
        <div className="bg-stone-200">
            <div className="flex flex-col items-center p-20 max-sm:p-0 max-sm:justify-center">
                <div className="text-6xl w-full font-bold p-10 max-sm:p-0 max-sm:text-3xl bg-white max-sm:pl-10">About</div>
                <div className="flex w-full h-full bg-white p-10 max-lg:flex-col" >
                    <div className="bg-sky-100 rounded-lg">
                        <img 
                        className="flex rounded-md p-5 w-80" 
                        src='./mainPicture.png' 
                        />
                    </div>
                    <div className="bg-white w-16 h-2"></div>
                    <div className="text-2xl w-10/12 bg-sky-100 rounded-lg max-sm:w-full">
                        <div className="py-5 px-16 max-sm:text-base max-sm:px-2">  
                            <p >Our app introduces a unique decision-making tool in the form of a spinning wheel, reminiscent of the whimsy of fortune.  
                            Designed to aid individuals facing the common conundrum of deciding what to eat for their meal, our app offers a delightful solution.</p>
                            <p><br></br></p> 
                            Users are presented with two intuitive options: 
                            '<span className="font-bold">Random</span>' and 
                            '<span className="font-bold">Manual</span>'. 
                            <p></p>
                            <p > </p>
                            <ul >
                                <li>
                                 &nbsp;&nbsp;●&nbsp;In the '<span className="font-bold">Random</span>' mode, the wheel selects a meal from a diverse pool of 70 food options, adding an element of surprise to meal selection. 
                                </li>
                                <li>
                                &nbsp;&nbsp;●&nbsp;Alternatively, in '<span className="font-bold">Manual</span>' mode, users exercise their choice by either selecting from a list of available foods or inputting their preferences. 
                                    
                                </li>
                            <p><br></br></p> 

                            &nbsp;After making a selection, users spin the wheel. Once the wheel halts, users are presented with four options: to spin again, locate nearby eateries through Google Maps, access cooking tutorials on YouTube, or explore recipes from our curated collection gathered from various online sources.
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}