import React from 'react'
import { Link } from 'react-router-dom'


const RecipeCard = ({ recipe }) => {
    const { image, label, cuisineType, dietLabel, mealType, uri } = recipe?.recipe

    const id = uri?.split("#")[1]

    return (
        <Link to={`/recipes/${id}`} className='flex justify-center w-full h-21  md:w-[220px]'>
        <div className='bg-_gradient shadow-lg w-60 rounded-lg h-full max-sm:w-80  '>
            <img src={image} alt={label} 
            className='rounded-lg h-[250px] md:h-[150px] w-full ' />

            <div className='p-3'>
                <p className='text-black font-semibold truncate'>{label}</p>

            </div>
            <div className='mt-2 flex  pb-2 px-3'>
                    <span className='px-2 py-1 text-[12px] capitalize rounded-md mr-3 text-black-500 truncate'>
                        cusine: {cuisineType}
                    </span>
                    <span className='px-2 py-1 text-[12px] capitalize bg-[#a2d729] shadow-xl rounded-md text-black-500'>
                        {mealType}
                    </span>
            </div>
            <div className='bg-[#3c91e6] hover:bg-stone-600'>
                <div  >
                   <div className='flex justify-between px-3 h-10 items-center'>
                        <p className='text-white'>Read Full Recipe</p> 
                        <p className='text-white'>→</p>

                   </div>
                </div>
            </div>
        </div>
    </Link>
    )
}

export default RecipeCard