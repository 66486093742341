interface Provider {
        value: string,
        label: string
}



export const data = [
        { option: 'Spaghetti Bolognese' },
        { option: 'Grilled Chicken Salad' },
        { option: 'Tacos' },
        { option: 'Roast Beef with Veggie' },
        { option: 'Veggie Stir-Fry' },
        { option: 'Margherita Pizza' },
        { option: 'Shrimp Scampi' },
        { option: 'Sushi Rolls' },
        { option: 'Chicken Parmesan' },
        { option: 'Beef Stew' },
        { option: 'Quinoa Salad' },
        { option: 'Pulled Pork Sandwich' },
        { option: 'Pad Thai' },
        { option: 'Baked Salmon' },
        { option: 'Falafel Wrap' },
        { option: 'Beef Burrito' },
        { option: 'Veggie Pizza' },
        { option: 'Chicken Alfredo' },
        { option: 'Veggie Burger' },
        { option: 'BBQ Ribs' },
        { option: 'Fettuccine Carbonara' },
        { option: 'Stuffed Bell Peppers' },
        { option: 'Chicken Fajitas' },
        { option: 'Seafood Paella' },
        { option: 'Greek Salad' },
        { option: 'Mushroom Risotto' },
        { option: 'Lentil Soup' },
        { option: 'Turkey Club Sandwich' },
        { option: 'Hawaiian Poke Bowl' },
        { option: 'Enchiladas' },
        { option: 'Lobster Mac and Cheese' },
        { option: 'Caprese Panini' },
        { option: 'Spinach Lasagna' },
        { option: 'Beef and Broccoli' },
        { option: 'Chicken Satay' },
        { option: 'Ratatouille' },
        { option: 'Teriyaki Tofu Bowl' },
        { option: 'Beef Kebabs' },
        { option: 'Eggplant Parmesan' },
        { option: 'Tex-Mex Chili' },
        { option: 'Chicken Shawarma' },
        { option: 'Jambalaya' },
        { option: 'Veggie Spring Rolls' },
        { option: 'Beef Pho' },
        { option: 'Chicken Piccata' },
        { option: 'Spanakopita' },
        { option: 'Stuffed Mushrooms' },
        { option: 'Pulled Chicken Tacos' },
        { option: 'Chicken Cesar Salad' },
        { option: 'Baked Ziti' },
        { option: 'Chicken and Waffles' },
        { option: 'Paella' },
        { option: 'Butternut Squash Risotto' },
        { option: 'Beef Tamales' },
        { option:  'Banh Mi Sandwich' },
        { option: 'Moroccan Tagine' },
        { option: 'Chicken Teriyaki' },
        { option: 'Lobster Roll' },
        { option: 'Chicken Tortilla Soup' },
        { option: 'Stuffed Cabbage Rolls' },
        { option: 'Beef Bulgogi' },
        { option: 'Shakshuka' },
        { option: 'Veggie Noodle Stir-Fry' },
        { option: 'Margherita Flatbread' },
        { option: 'Thai Green Curry' },
        { option: 'Greek Moussaka' },
        { option: 'Beef Empanadas' },
        { option: 'Veggie Paella' },
        { option: 'Chicken Caesar Wrap' },
        { option: 'Quesadillas' }
      ]
      
//       export const data = [   "Pizza", "Hambuger", "Sushi", "Taco", 
//       "Hot Dog", "Brisket",
//         "Chicken Wings", "Pho", "Curry", "BBQ", "Spaghetti",
//         "Sandwitch", "Steak", "Salmon", "Fried rice", "Bibibop",
//         "Gyros", "Fried Shrimp", "BBQ Short Ribs", "Burrito", "Chili"
// ]
    
// }

export const menuOptions: Array<Provider> = [
        { value: 'Spaghetti Bolognese', label: 'Spaghetti Bolognese' },
  { value: 'Grilled Chicken Salad', label: 'Grilled Chicken Salad' },
  { value: 'Tacos', label: 'Tacos' },
  {
    value: 'Roast Beef with Vegetables',
    label: 'Roast Beef with Vegetables'
  },
  { value: 'Veggie Stir-Fry', label: 'Veggie Stir-Fry' },
  { value: 'Margherita Pizza', label: 'Margherita Pizza' },
  { value: 'Shrimp Scampi', label: 'Shrimp Scampi' },
  { value: 'Sushi Rolls', label: 'Sushi Rolls' },
  { value: 'Chicken Parmesan', label: 'Chicken Parmesan' },
  { value: 'Beef Stew', label: 'Beef Stew' },
  { value: 'Quinoa Salad', label: 'Quinoa Salad' },
  { value: 'Pulled Pork Sandwich', label: 'Pulled Pork Sandwich' },
  { value: 'Pad Thai', label: 'Pad Thai' },
  { value: 'Baked Salmon', label: 'Baked Salmon' },
  { value: 'Falafel Wrap', label: 'Falafel Wrap' },
  { value: 'Beef Burrito', label: 'Beef Burrito' },
  { value: 'Veggie Pizza', label: 'Veggie Pizza' },
  { value: 'Chicken Alfredo', label: 'Chicken Alfredo' },
  { value: 'Veggie Burger', label: 'Veggie Burger' },
  { value: 'BBQ Ribs', label: 'BBQ Ribs' },
  { value: 'Fettuccine Carbonara', label: 'Fettuccine Carbonara' },
  { value: 'Stuffed Bell Peppers', label: 'Stuffed Bell Peppers' },
  { value: 'Chicken Fajitas', label: 'Chicken Fajitas' },
  { value: 'Seafood Paella', label: 'Seafood Paella' },
  { value: 'Greek Salad', label: 'Greek Salad' },
  { value: 'Mushroom Risotto', label: 'Mushroom Risotto' },
  { value: 'Lentil Soup', label: 'Lentil Soup' },
  { value: 'Turkey Club Sandwich', label: 'Turkey Club Sandwich' },
  { value: 'Hawaiian Poke Bowl', label: 'Hawaiian Poke Bowl' },
  { value: 'Enchiladas', label: 'Enchiladas' },
  { value: 'Lobster Mac and Cheese', label: 'Lobster Mac and Cheese' },
  { value: 'Caprese Panini', label: 'Caprese Panini' },
  { value: 'Spinach Lasagna', label: 'Spinach Lasagna' },
  { value: 'Beef and Broccoli', label: 'Beef and Broccoli' },
  { value: 'Chicken Satay', label: 'Chicken Satay' },
  { value: 'Ratatouille', label: 'Ratatouille' },
  { value: 'Teriyaki Tofu Bowl', label: 'Teriyaki Tofu Bowl' },
  { value: 'Beef Kebabs', label: 'Beef Kebabs' },
  { value: 'Eggplant Parmesan', label: 'Eggplant Parmesan' },
  { value: 'Tex-Mex Chili', label: 'Tex-Mex Chili' },
  { value: 'Chicken Shawarma', label: 'Chicken Shawarma' },
  { value: 'Jambalaya', label: 'Jambalaya' },
  { value: 'Veggie Spring Rolls', label: 'Veggie Spring Rolls' },
  { value: 'Beef Pho', label: 'Beef Pho' },
  { value: 'Chicken Piccata', label: 'Chicken Piccata' },
  { value: 'Spanakopita', label: 'Spanakopita' },
  {
    value: 'Stuffed Mushrooms',
    label: 'Stuffed Mushrooms'
  },
  { value: 'Pulled Chicken Tacos', label: 'Pulled Chicken Tacos' },
  {
    value: 'Cesar Salad with Chicken',
    label: 'Cesar Salad with Chicken'
  },
  { value: 'Baked Ziti', label: 'Baked Ziti' },
  { value: 'Chicken and Waffles', label: 'Chicken and Waffles' },
  { value: 'Paella', label: 'Paella' },
  {
    value: 'Butternut Squash Risotto',
    label: 'Butternut Squash Risotto'
  },
  { value: 'Beef Tamales', label: 'Beef Tamales' },
  {
    value: 'Vietnamese Banh Mi Sandwich',
    label: 'Vietnamese Banh Mi Sandwich'
  },
  { value: 'Moroccan Tagine', label: 'Moroccan Tagine' },
  {
    value: 'Chicken Teriyaki Stir-Fry',
    label: 'Chicken Teriyaki Stir-Fry'
  },
  { value: 'Lobster Roll', label: 'Lobster Roll' },
  { value: 'Chicken Tortilla Soup', label: 'Chicken Tortilla Soup' },
  { value: 'Stuffed Cabbage Rolls', label: 'Stuffed Cabbage Rolls' },
  { value: 'Beef Bulgogi', label: 'Beef Bulgogi' },
  { value: 'Shakshuka', label: 'Shakshuka' },
  { value: 'Veggie Noodle Stir-Fry', label: 'Veggie Noodle Stir-Fry' },
  { value: 'Margherita Flatbread', label: 'Margherita Flatbread' },
  { value: 'Thai Green Curry', label: 'Thai Green Curry' },
  { value: 'Greek Moussaka', label: 'Greek Moussaka' },
  { value: 'Beef Empanadas', label: 'Beef Empanadas' },
  { value: 'Veggie Paella', label: 'Veggie Paella' },
  { value: 'Chicken Caesar Wrap', label: 'Chicken Caesar Wrap' },
  { value: 'Quesadillas',    label: 'Quesadillas'
  }
]