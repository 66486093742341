import {Routes, Route, Outlet} from 'react-router-dom'
import Home from "./pages/Home";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import RecipeDetail from './pages/RecipeDetail';
import Recipes from './recipes/components/Recipes'
import About from './pages/About'
// import FoodList from './foods/foodsList'

function Layout(){
  return (
    <>
      <Navbar/>
        <Outlet/>
      <Footer/>
    </>
  )
}
function App() {
  return (
    <div className='bg-blac flex flex-col h-full '>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='recipes' element={<Recipes />} />
          <Route path='recipes/:id' element={<RecipeDetail />} />
          <Route path='about' element={<About />} />
          {/* <Route path='foods' element={<FoodList />} /> */}

        </Route>
      </Routes>
    </div>
  );
}

export default App;
